import React, { useCallback, useEffect, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import './Calculator.css';
import { IMainScreenState } from "../../types/IMainScreenState";
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import SubscriptionSelect from '../Selector/SubscriptionSelect';
import { useFetch } from '../../core/http/fetch.hook';
import { ISubscription } from '../../types/ISubscription';
import { DefaultSubscription } from '../../core/getSubscriptions/default-subscription';

export interface ICalculatorProps {
  data: IMainScreenState;
  onClick?: () => void;
  onDataChange?: (state: IMainScreenState) => void;
}

// Podarok
const Calculator: React.FC<ICalculatorProps> = ({ data, onClick, onDataChange = () => { } }) => {
  const [availableSubscriptions, setAvailableSubscriptions] = useState<ISubscription[]>([DefaultSubscription])
  const [activeSubscription, setActiveSubscription] = useState<ISubscription>(DefaultSubscription);

  const { isLoading, response } = useFetch('promocodes/prices')

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value ?? "";
    onDataChange({
      ...data,
      email,
    })
  }, [data, onDataChange])

  const handleConfirmEmailCheckbox = useCallback(() => {
    onDataChange({
      ...data,
      confirmEmail: data.confirmEmail ? false : true,
    })
  }, [data, onDataChange])

  const onSelectSubscription = useCallback((subscription: ISubscription) => {
    setActiveSubscription(subscription)
    onDataChange({
      ...data,
      months: subscription.months,
      price: subscription.finalPrice,
    });
  }, [data, onDataChange])

  useEffect(() => {
    if (response !== undefined) {
      const { podarok } = response;
      if (podarok.length) {
        podarok.sort((a: ISubscription, b: ISubscription) => (
          (a.months < b.months) ? 1 : -1
        ))
        setAvailableSubscriptions(podarok);
        setActiveSubscription(podarok[0]);
        onSelectSubscription(podarok[0]);
      }
    }
  }, [response])

  return (
    <div className="CalculatorBlock">
      <div>
        <p className="CalculatorTitle">Оплатить подписку</p>
        <CustomInput placeholder="Ваша электронная почта" onChange={handleEmailChange} />
        <p className="CalculatorAdditionalText">На указанную почту будет отправлен сертификат</p>
        <div className="CalculatorBottomBlock">
          <div className="CalculatorBottomLeftBlock">
            <p className="CalculatorTextFirst">Месяцев</p>
            <p className="CalculatorText">Цена</p>
            {!!activeSubscription.discount1 && <p className="CalculatorText">Скидка</p>}
            {!!activeSubscription.discount2 && <p className="CalculatorText">Доп.скидка</p>}
            {!!activeSubscription.discount1 && <p className="CalculatorTextSum">Итого к оплате</p>}
          </div>
          <div className="CalculatorBottomRightBlock">
            <SubscriptionSelect
              availableSubscriptions={availableSubscriptions}
              activeSubscription={activeSubscription}
              onSelect={onSelectSubscription}
            />
            <div className="CalculatorBottomRightBlockText">
              <p className="CalculatorText">
                {activeSubscription.price} <span className="RubleIcon">₽</span>
              </p>
              {!!activeSubscription.discount1 && <p className="CalculatorText">-{activeSubscription.discount1}%</p>}
              {!!activeSubscription.discount2 && <p className="CalculatorText">-{activeSubscription.discount2}%</p>}
              {!!activeSubscription.discount1 &&
                <p className="CalculatorTextSum">
                  {activeSubscription.finalPrice} <span className="RubleIcon">₽</span>
                </p>}
            </div>
          </div>
        </div>
      </div>
      <div className="CalculatorFooter">
        <CustomCheckbox
          title="Подтверждаю, что Email введен верно"
          value={data.confirmEmail}
          onChange={handleConfirmEmailCheckbox}
          disabled={data.email === ''}
        />
        <CustomButton title="Оплатить в подарок" onClick={onClick} disabled={!data.canPay} />
        <p className="CalculatorOffer">
          {"Оформляя покупку, вы соглашаетесь с "}
          <a className="CalculatorLink" href="https://privacy.podarok.prostoapp.ru">Договором оферты</a>
        </p>
      </div>
    </div>
  );
}

export default Calculator;
