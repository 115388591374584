import styles from "./CorporateCalculator.module.css";
import React, { useCallback, useState } from "react";
import CustomInput from "../CustomInput/CustomInput";
import Selector from "../Selector/Selector";
import CustomButton from "../CustomButton/CustomButton";
import CorporateRadio from "./CorporateRadio";
import { ICorporateScreenState } from "../../types/ICorporateScreenState";
import { TPayMethod } from "../../types/TPayMethod";
import PhoneInput from "react-phone-input-2";

export interface ICorporateCalculatorProps {
  data: ICorporateScreenState;
  onClick?: () => void;
  onDataChange: (state: ICorporateScreenState) => void;
}

const CorporateCalculator: React.FC<ICorporateCalculatorProps> = ({ data, onClick, onDataChange }) => {
  const [price, setPrice] = useState(
    {
      count: 1,
      newPrice: 379,
      discount: 34,
      corporateDiscount: 65,
      benefit: 0,
      month: 12
    }
  );

  const handlePhoneChange = useCallback((phoneNumber: string) => {
    onDataChange({
      ...data,
      phone: phoneNumber,
    });
  }, [data, onDataChange]);

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({
      ...data,
      email: event.target.value,
    });
  }, [data, onDataChange]);

  const handleCompanyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({
      ...data,
      companyName: event.target.value,
    });
  }, [data, onDataChange]);

  const handleINNChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({
      ...data,
      inn: event.target.value,
    });
  }, [data, onDataChange]);

  const handleWorkPhoneChange = useCallback((phoneNumber: string) => {
    onDataChange({
      ...data,
      workPhone: phoneNumber,
    });
  }, [data, onDataChange]);

  const handleAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({
      ...data,
      legalAddress: event.target.value,
    });
  }, [data, onDataChange]);

  const getPrice = useCallback((priceInfo) => {
    setPrice(priceInfo);
    onDataChange({
      ...data,
      subscriptionCount: priceInfo.count,
      subscriptionDuration: priceInfo.month,
      subscriptionPrice: (priceInfo.newPrice - priceInfo.benefit) * priceInfo.count,
      discount: priceInfo.discount,
      corporateDiscount: priceInfo.corporateDiscount,
    })
  }, [data, onDataChange]);

  const handlePromoCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const PROMO_CODE_SYMBOLS = /^[A-Z\d]*$/;
    const newValue = event.target.value.toUpperCase();

    if (PROMO_CODE_SYMBOLS.test(newValue)) {
      onDataChange({
        ...data,
        promoCode: newValue,
      })
    }
  }, [data, onDataChange]);

  const handlePayMethodChange = useCallback((newValue: TPayMethod) => {
    onDataChange({
      ...data,
      payMethod: newValue
    })
    
  }, [data, onDataChange])
  

  return (
    <div className={styles.CalculatorBlock}>
      <div>
        <div className={styles.CalculatorTitleContainer}>
          <p className={styles.CalculatorTitle}>Подарить спокойствие своим сотрудникам это просто.</p>
        </div>
        <div className={styles.PhoneInput}>
          <PhoneInput country={'ru'} value={data.phone} inputProps={{required: true, autoFocus: true}} onChange={handlePhoneChange} specialLabel="" />
        </div>
        <CustomInput type="email" className={styles.Email} inputClassName="Email" placeholder="Ваша электронная почта" onChange={handleEmailChange} />
        <p className={styles.CalculatorAdditionalText}>На указанную почту будет отправлен сертификат</p>
        <div className={styles.CalculatorBottomBlock}>
          <div className={styles.CalculatorBottomLeftBlock}>
            <p className={styles.CalculatorTextFirst}>Число подписок</p>
            <p className={styles.CalculatorTextSecond}>Тип подписки</p>
            <p className={styles.CalculatorText}>Цена</p>
            {!!price.discount && <p className={styles.CalculatorText}>Скидка за период</p>}
            {!!price.corporateDiscount && <p className={styles.CalculatorText}>Скидка корпоративная</p>}
            <p className={styles.CalculatorText}>Итого к оплате</p>
          </div>
          <div className={styles.CalculatorBottomRightBlock}>
            <Selector getPrice={getPrice} showCountSelector useCorporateDiscount />
            <div className={styles.CalculatorBottomRightBlockText}>
              <p className={styles.CalculatorText}>
                {price.newPrice.toLocaleString('ru-RU') || 0} <span className="RubleIcon">₽</span>
              </p>
              {!!price.discount && <p className={styles.CalculatorText}>-{price.discount}%</p>}
              {!!price.corporateDiscount && <p className={`${styles.CalculatorText} ${styles.CalculatorText2x}`}>-{price.corporateDiscount}%</p>}
              <p className={styles.CalculatorText}>
                {((price.newPrice - price.benefit) * price.count).toLocaleString('ru-RU') || 0} <span className="RubleIcon">₽</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.Email}>
          <input
            className={styles.PromoCodeInput}
            placeholder="Промокод"
            value={data.promoCode}
            onChange={handlePromoCodeChange}
            minLength={4}
            maxLength={16}
          />
          <p className={styles.CalculatorAdditionalText}>Ваше пожелание по тому, как должен выглядеть промокод</p>

        </div>
        <CorporateRadio value={data.payMethod} onChange={handlePayMethodChange} />
        {(data.payMethod === 'bill') && <CustomInput type="text" className={styles.Email} inputClassName="Email" placeholder="Название компании" onChange={handleCompanyChange} minLength={3} maxLength={100} />}
        {(data.payMethod === 'bill') && <CustomInput type="text" className={styles.Email} inputClassName="Email" placeholder="ИНН" onChange={handleINNChange} minLength={10} maxLength={12} />}
        {(data.payMethod === 'bill') && <div className={styles.WorkPhoneInput}>
          <PhoneInput value={data.workPhone} inputProps={{required: true}} onChange={handleWorkPhoneChange} specialLabel="" placeholder="Рабочий телефон"/>
        </div>}
        {(data.payMethod === 'bill') && <CustomInput type="text" className={styles.Email} inputClassName="Email" placeholder="Юридический адрес" onChange={handleAddressChange} minLength={3} maxLength={20} />}
       </div>
      <div className={styles.CalculatorFooter}>
        <CustomButton title="Оформить корпоративный подарок" onClick={onClick} disabled={!data.canPay} />
        <p className={styles.CalculatorOffer}>
          {"Оформляя покупку, вы соглашаетесь с "}
          <a className="CalculatorLink" href="https://privacy.podarok.prostoapp.ru">Договором оферты</a>
        </p>
      </div>
    </div>
  );
}

export default CorporateCalculator;
