import React from "react";
import config from "../core/config/config";
import domainRouter from "./domainRouter";
import MainRoutes from "./MainRoutes";
import CodeRoutes from "./CodeRoutes";
import CorporativeRoutes from "./CorporativeRoutes";
import PromoRoutes from "./PromoRoutes";

interface IProps {
  className?: string;
}

const SCREENS = {
  [config.giftRoute]: MainRoutes,
  [config.codeRoute]: CodeRoutes,
  [config.corpRoute]: CorporativeRoutes,
  [config.promoRoute]: PromoRoutes,
}

const DomainRoutes: React.FC<IProps> =({ className }) => {
  const Screen = SCREENS[domainRouter.currentHost];
  if (Screen) {
    return (
      <div className={className}>
        <Screen />
      </div>
    );
  }
  return <div>Unknown host</div>;
};

export default DomainRoutes;
