import React from 'react';
import AdvicesBlock from '../AdvicesBlock/AdvicesBlock';
import CertificateBlock from '../CertificateBlock/CertificateBlock';
import CustomButton from '../CustomButton/CustomButton';
import './BottomMainBlock.css';

interface IProps {
  onActivateClick?: () => void;
  removeTitle?: boolean;
  buttonTitle: string;
  downloadIcon?: boolean;
  firstImage?: string;
  firstText?: string;
  secondText?: string;
  certificate?: boolean;
  mainScreen?: boolean;
}

const BottomMainBlock: React.FC<IProps> = ({ onActivateClick, removeTitle, buttonTitle, downloadIcon, firstImage, firstText, secondText, certificate, mainScreen }) => {
  return (
    <div className="MainBottomBlock">
     {!removeTitle && <a id="advices" className="Title">Как это работает?</a>}
     {certificate && <CertificateBlock firstImage={firstImage} firstText={firstText} secondText={secondText} />}
     {mainScreen && <AdvicesBlock firstImage={firstImage} firstText={firstText} secondText={secondText} />}
     <div className="BottomMainBlockButton">
      <CustomButton downloadIcon={downloadIcon} title={buttonTitle} onClick={onActivateClick} />
     </div>
    </div>
  );
}

export default BottomMainBlock;
