import React, { useCallback, useState } from 'react';
import { useHistory } from "react-router-dom";
import CustomButton from '../../components/CustomButton/CustomButton';
import Footer from '../../components/Footer/Footer';
import PhoneInput from 'react-phone-input-2'
import './CodeActivation.css';
import ActivatePromoCodeController from '../../core/activatePromoCode/controller';
import domainRouter from "../../navigation/domainRouter";
import config from '../../core/config/config';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';

const prostoAppIcon = "images/ProstoappIcon.png";
const CodeActivationPresent = "images/Present.png";
const CodeActivationMainIcon = "images/CodeActivationMain.png";

const controller = new ActivatePromoCodeController();

const CodeActivation: React.FC = () => {
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPhone, setConfirmPhone] = useState(false);
  const [promocodeFlag, setPromocodeFlag] = useState(false);
  const [phoneFlag, setPhoneFlag] = useState(false);
  const [error, setError] = useState("")
  const history = useHistory();


  const handleActivation = useCallback(async () => {
    const activateResult = await controller.activatePromoCode(code, phone);

    if (activateResult.success) {
      history.push("/activated");
      return;
    }

    setError(activateResult.errorMessage ?? "Неизвестная ошибка");

  }, [code, phone]);

  const handleBuy = useCallback(() => {
    domainRouter.go(config.giftRoute);
  }, [])

  const handleCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value.replace(/\W/g, '').toUpperCase();

      setCode(text);
      const reg = /[A-Za-z0-9]+/;
      const validation = reg.test(text);
      if (validation) {
        setPromocodeFlag(true);
      } else {
        setPromocodeFlag(false);
      }

    }, [setCode, setPromocodeFlag])

  const handlePhone = useCallback(
    (phoneNumber: string) => {
      setPhone(phoneNumber);
      if (phoneNumber.length >= 4 && phoneNumber.length <= 20) {
        setPhoneFlag(true);
      } else {
        setPhoneFlag(false);
      }
    }, [setPhone, setPhoneFlag])


  return (
    <div>
      <div className="CodeActivationBlock">
        <img className="ProstoAppIconMain" src={prostoAppIcon} />
        <div className="CodeActivationUpper">
          <img className="CodeActivationMainIcon" src={CodeActivationMainIcon} />
          <p className="CodeActivationTitle">Активация промокода</p>
          <div className="CodeActivationInputBlock">
            <div className="CodeActivationInput">
              <PhoneInput
                enableLongNumbers
                country={'ru'}
                value={phone}
                inputProps={{ required: true, autoFocus: true }}
                onChange={handlePhone} specialLabel=""
              />
              <CustomCheckbox title="Номер введен верно" value={confirmPhone} onChange={setConfirmPhone} />
              <p className="CodeActivationAdditionalText">На этот номер телефона будет предоставлена подписка в подарок</p>
            </div>
            <div className="CodeActivationInput">
              <input placeholder="Промокод" onChange={handleCode} value={code} minLength={4} maxLength={20} />
            </div>
            <div className="CodeActivationInput">
              <CustomButton title="Активировать" onClick={handleActivation} disabled={!promocodeFlag || !phoneFlag || !confirmPhone} />
            </div>
          </div>
          <div className="CodeActivationError">{error}</div>
          <div className="CodeActivationBuy">
            <img className="CodeActivationPresent" src={CodeActivationPresent} />
            <div className="CodeActivationBuyButton">
              <CustomButton title="Подарить подпиcку" onClick={handleBuy} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CodeActivation;
