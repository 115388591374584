import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CertificateScreen from '../screens/CertificateScreen/CertificateScreen';
import CorporateSubscription from '../screens/CorporateSubscription/CorporateSubscription';
import ManagerScreen from '../screens/ManagerScreen/ManagerScreen';

const CorporativeRoutes: React.FC = () => {

  return (
    <div>
      <Switch>
        <Route
          exact path='/'
          component={CorporateSubscription}
        />
        <Route
          exact path='/manager_screen'
          component={ManagerScreen}
        />
        <Route
          exact path='/certificate_screen'
          component={CertificateScreen}
        />
      </Switch>
    </div>
  );
}

export default CorporativeRoutes;
