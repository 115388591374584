import styles from "./CodeActivated.module.css";
import React, { useCallback } from "react";
import CustomButton from "../../components/CustomButton/CustomButton";
import Footer from "../../components/Footer/Footer";

const prostoAppIcon = "images/ProstoappIcon.png";
const activatedIcon = "images/Firework.png";

const CodeActivated: React.FC = () => {
  const handleIOsClick = useCallback(() => {
    window.location.href = "https://apps.apple.com/ru/app/просто-медитация-и-сон/id1490891826?mt=8";
  }, []);

  const handleAndroidClick = useCallback(() => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.prostoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
  }, []);

  return (
    <div className={styles.Root}>
      <img className={styles.AppIcon} src={prostoAppIcon} />
      <div className={styles.UpperBlock}>
        <img className={styles.ActivatedIcon} src={activatedIcon} />
        <p className={styles.TitleText}>Промокод успешно активирован</p>
        <p className={styles.Text}>
          Войдите в приложение под указанным номером телефона.
          Вы увидите сообщение, что получили подписку в подарок
        </p>
      </div>
      <div className={styles.Buttons}>
        <CustomButton className={styles.Button} title={"Установить IOS"} onClick={handleIOsClick} />
        <CustomButton className={styles.Button} title={"Установить Android"} onClick={handleAndroidClick} />
      </div>
      <Footer />
    </div>
  );
}

export default CodeActivated;
