import React, { useCallback, useState } from 'react';
import BottomMainBlock from '../../components/BottomMainBlock/BottomMainBlock';
import Footer from '../../components/Footer/Footer';
import UpperMainBlock from '../../components/UpperMainBlock/UpperMainBlock';
import BuyGiftSubscriptionController from "../../core/buyGiftSubscription/controller";
import ym from 'react-yandex-metrika';
import { IMainScreenState } from "../../types/IMainScreenState";
import config from '../../core/config/config';
import domainRouter from "../../navigation/domainRouter";
import { useHistory } from 'react-router-dom';
import debounce from "lodash.debounce";

const controller = new BuyGiftSubscriptionController();
const wallet = "images/Wallet.png";
const firstBlockText = "Оплатите подарочную карту для себя или близкого человека";
const secondBlockText = "Вы получите сертификат, который можно распечатать или отправить через мессенджер";

const MainScreen: React.FC = () => {
  const [screenState, setScreenState] = useState<IMainScreenState>({
    email: "",
    confirmEmail: false,
    promocodeId: null,
    text: "" || null,
    months: 0,
    price: 0,
    phone: "",
    discount: 0,
    canPay: false,
  });
  const history = useHistory();

  const handleDataChange = useCallback((newState: IMainScreenState) => {
    setScreenState({
      ...newState,
      canPay: isValidEmail(newState.email) && newState.confirmEmail,
    });
  }, []);

  const handleBuyClick = useCallback(debounce(async () => {
    const promocode = await controller.buyPromoCode(screenState.email, screenState.months, screenState.price, screenState.discount);

    if (!promocode) {
      alert("Не получилось сгенерировать промокод! Попробуйте ещё раз.");
      return;
    }

    setScreenState({ ...screenState, promocodeId: promocode.id, text: promocode.text });
    ym('reachGoal', 'buy_certificate');
    history.push({
      pathname: '/certificate_screen',
      state: {
        promocodeId: promocode.id,
        text: promocode.text,
      },
    });
  }, 2000, { leading: true, trailing: false }), [screenState.email, screenState.months, screenState.price])

  const handleActivateClick = useCallback(() => {
    domainRouter.go(config.codeRoute);
  }, []);

  return (
    <>
      <UpperMainBlock data={screenState} onDataChange={handleDataChange} onBuyClick={handleBuyClick}/>
      <BottomMainBlock buttonTitle={"Активировать промокод"} mainScreen onActivateClick={handleActivateClick} firstImage={wallet} firstText={firstBlockText} secondText={secondBlockText} />
      <Footer />
    </>
  );
}

const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function isValidEmail(value: string): boolean {
  return EMAIL_REG.test(value);
}

export default MainScreen;
