import config from "../config/config";
import httpService from "../http/HttpService";

const PROMO_CODES_URL = `${config.apiUrl}/promocodes`;

interface ICertificateRequest {
  id: string;
  text: string;
}

class DownloadCertificateApi {
  async downloadCert(newData: ICertificateRequest): Promise<any> {
    const response = await httpService.get(`${PROMO_CODES_URL}/${newData.id}/certificate?text=${newData.text}`, undefined, {headers: {"Content-type": "application/pdf"}, encoding: "binary",}) 
    return response;
  }
}

export default DownloadCertificateApi;
