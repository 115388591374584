import React from 'react';
import './CustomButton.css';

const downloadIconImage = "images/Download.png";

export interface ICustomButtonProps {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  downloadIcon?: boolean;
  className?: string;
}

const CustomButton: React.FC<ICustomButtonProps> = ({title, onClick, disabled, downloadIcon, className = ""}) => {
  return (
      <div className={`CustomButtonBlock ${className}`}>
      <button className="CustomButton" onClick={onClick} disabled={disabled}>{downloadIcon && <img className="DownloadIcon" src={downloadIconImage} />}<span>{title}</span></button>
    </div>
  );
}

export default CustomButton;
