import React from 'react';
import './AdvicesBlock.css';

const letter = "images/Letter.png";
const certificate = "images/Certificate.png";
interface IProps {
  firstImage?: string;
  firstText?: string;
  secondText?: string;
}

const certificateInLetter = "images/CertificateInLetter.png";

const AdvicesBlock: React.FC<IProps> = ({ firstImage, firstText, secondText}) => {

  return ( 
    <div className="AdvicesBlock">
      <div>
      <div className="AdvicesContainer">
        <img className="AdvicesImage" src={firstImage} />
        <p className="AdvicesTextFirst">{firstText}</p>
      </div>
      <div className="AdvicesContainer">
        <img className="AdvicesImage" src={letter} />
        <p className="AdvicesText">{secondText}</p>
      </div>
      <div className="AdvicesContainer">
        <img className="AdvicesImage" src={certificate} />
        <p className="AdvicesText">Получатель сертификата сможет легко активировать промокод, инструкция есть в сертификате</p>
      </div>
      </div>
      <div>
        <img className="AdvicesLetterImage" src={certificateInLetter} />
      </div>
    </div>
  );
}

export default AdvicesBlock;
