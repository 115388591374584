import React, { useCallback, useEffect, useState } from 'react';
import { ICalculator } from '../../types/ICalculator';
import './Selector.css';
import IntegerSelector from "../IntegerSelector/IntegerSelector";

const monthPrice = 379;
const selectorIcon = "images/Selector.png";

export interface ISelectorProps {
  getPrice: (data: ICalculator) => void;
  showCountSelector?: boolean;
  useCorporateDiscount?: boolean;
  promoCalculator?: boolean;
  useAvoDiscount?: boolean;
}

interface IDates {
  [key: string]: string;
}

const dates: IDates = {
  "1": "1 месяц",
  "3": "3 месяца",
  "6": "6 месяцев",
  "12": "12 месяцев",
};

const Selector: React.FC<ISelectorProps> = ({ getPrice, showCountSelector, useCorporateDiscount, promoCalculator, useAvoDiscount }) => {

  const [count, setCount] = useState(3);
  const [value, setValue] = useState(!promoCalculator ? 12 : 6);
  const [price, setPrice] = useState(monthPrice * (!promoCalculator ? 12 : 6));
  const [discount, setDiscount] = useState(!promoCalculator ? 52 : 40);
  const [corporateDiscount, setCorporateDiscount] = useState(65);

  useEffect(() => {
    let benefit = Math.round(price * discount / 100);

    if (useCorporateDiscount) {
      if (discount === 0) {
        benefit = 0;
      } else {
        const preciseBenefit = price * (1 - (1 - discount / 100) * (1 - corporateDiscount / 100));
        benefit = Math.ceil(preciseBenefit);
      }
    }

    if (useAvoDiscount) {
      benefit = benefit + Math.ceil((price - benefit) * 0.55);
    }

    getPrice(
      {
        count,
        newPrice: price,
        discount,
        corporateDiscount,
        benefit,
        month: value
      }
    )
  }, [value, count]);

  const handleMonthsChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setPrice(parseInt(event.target.value) * monthPrice);
      setValue(parseInt(event.target.value));
      if (parseInt(event.target.value) === 12) {
        setDiscount(52);
        setCorporateDiscount(65);
      } else if (parseInt(event.target.value) === 6) {
        setDiscount(useCorporateDiscount ? 40 : 54);
        setCorporateDiscount(65);
      } else if (parseInt(event.target.value) === 3) {
        setDiscount(30);
        setCorporateDiscount(0);
      } else {
        setDiscount(0);
        setCorporateDiscount(0);
      }
    },
    [setValue, setDiscount, useCorporateDiscount],
  );

  const handleCountChange = useCallback((newValue: number) => {
    
    if (newValue >= 3) {
      setCount(newValue);
    }
  }, [])

  return (
    <div>
      {showCountSelector && <IntegerSelector className="IntegerSelector" onChange={handleCountChange} />}
      <div className="Selector">
        <div>
          <select onChange={handleMonthsChange} className="InputDate" defaultValue={12} disabled={useAvoDiscount}>
            {!useCorporateDiscount && ( // it's a bad way to write something like this, but no time for refactoring
              <option value={1}>1 месяц</option>
            )}
            {!useCorporateDiscount && ( // it's a bad way to write something like this, but no time for refactoring
              <option value={3}>3 месяца</option>
            )}
            <option value={6}>6 месяцев</option>
            <option value={12}>12 месяцев</option>
          </select>
          {dates[value.toString()]}
          <img className="SelectorImage" src={selectorIcon} />
        </div>
      </div>
    </div>
  );
};

export default Selector;
