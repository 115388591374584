import ActivatePromoCodeApi from "./api";

const ACTIVATE_ERRORS: Record<number, string> = {
  1013: "Промокод уже активирован",
  1014: "Промокод не найден",
  1015: "Промокод не оплачен",
  1016: "Некорректные символы в промокоде",
  1017: "Вы уже активировали этот промокод",
};

interface IActivateResult {
  success: boolean;
  errorMessage?: string;
}

class ActivatePromoCodeController {
  private api = new ActivatePromoCodeApi();

  async activatePromoCode(promocode: string, phone: string): Promise<IActivateResult> {
    const response = await this.api.activate({
      phone,
      promocode
    });

    return {
      success: response.success,
      errorMessage: ACTIVATE_ERRORS[response.responseStatus!] ?? undefined,
    };
  }
}

export default ActivatePromoCodeController;
