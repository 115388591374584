import React, { useCallback, useEffect, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import './PromoCalculator.css';
import { IMainScreenState } from "../../types/IMainScreenState";
import PhoneInput from 'react-phone-input-2';
import CustomInput from '../CustomInput/CustomInput';
import ActivateCodePromocodeController from '../../core/activateCodePromocode/controller';
import { DefaultSubscription } from '../../core/getSubscriptions/default-subscription';
import { useFetch } from '../../core/http/fetch.hook';
import { ISubscription } from '../../types/ISubscription';
import SubscriptionSelect from '../Selector/SubscriptionSelect';

export interface ICalculatorProps {
  data: IMainScreenState;
  onClick?: () => void;
  onDataChange?: (state: IMainScreenState) => void;
}

// Promo
const PromoCalculator: React.FC<ICalculatorProps> = ({ data, onClick, onDataChange = () => { } }) => {
  const { response } = useFetch('promocodes/prices')

  const [availableSubscriptions, setAvailableSubscriptions] = useState<ISubscription[]>([DefaultSubscription])
  const [activeSubscription, setActiveSubscription] = useState<ISubscription>(DefaultSubscription);

  const [phone, setPhone] = useState("");
  const [phoneFlag, setPhoneFlag] = useState(false);

  const [code, setCode] = useState(false);
  const [codeData, setCodeData] = useState("");
  const [error, setError] = useState(false);
  const [codeReq, setCodeReq] = useState(true);
  const [promocodeDiscount, setPromocodeDiscount] = useState(0);

  const handleCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value.replace(/\W/g, '').toUpperCase();
      setCodeData(text);
      if (text.length > 3) {
        setCodeReq(false);
      } else {
        setCodeReq(true);
      }
    }, [])

  const handlePhone = useCallback(
    (phone: string) => {
      setPhone(phone);
      onDataChange({
        ...data,
        phone,
      })
      if (phone.length >= 4 && phone.length <= 20) {
        setPhoneFlag(true);
      } else {
        setPhoneFlag(false);
      }
    }, [setPhone, setPhoneFlag, data, onDataChange])

  const onClickCode = useCallback(async () => {
    const controller = new ActivateCodePromocodeController();
    const activateResult = await controller.activateCodePromocode(codeData);
    if (activateResult.success) {
      setCode(true);
      setError(false);
      const promocodeDiscount = activateResult.data.discount;
      setPromocodeDiscount(promocodeDiscount);
      const newPrice = Math.floor(data.price - data.price * promocodeDiscount / 100);
      onDataChange({
        ...data,
        price: newPrice,
      });
      return;
    } else {
      setError(true);
      setCode(false);
      setPromocodeDiscount(1);
      onDataChange({
        ...data,
        price: activeSubscription.finalPrice,
      });
    }
  }, [data, codeData, activeSubscription, onDataChange])

  const onClickResetCode = useCallback(() => {
    setCodeData('');
    setPromocodeDiscount(0);
    onDataChange({
      ...data,
      price: activeSubscription.finalPrice,
    });
    setCodeReq(true);
    setCode(false);
  }, [data, activeSubscription, onDataChange])

  const onSelectSubscription = useCallback((subscription: ISubscription) => {
    setActiveSubscription(subscription)
    onDataChange({
      ...data,
      months: subscription.months,
      price: subscription.finalPrice,
    });
  }, [data, onDataChange])

  useEffect(() => {
    if (response !== undefined) {
      const { podarok } = response; // change to promo in the future release
      if (podarok.length) {
        podarok.sort((a: ISubscription, b: ISubscription) => (
          (a.months < b.months) ? 1 : -1
        ))
        setAvailableSubscriptions(podarok);
        setActiveSubscription(podarok[0]);
        onSelectSubscription(podarok[0]);
      }
    }
  }, [response])

  return (
    <div className="CalculatorBlock">
      <div>
        <p className="CalculatorTitle">Оформить подписку</p>
        <div className="CalculatorPhoneInput">
          <PhoneInput country={'ru'} value={phone} inputProps={{ required: true, autoFocus: true }} onChange={handlePhone} specialLabel="" />
        </div>
        <p className="CalculatorAdditionalText">На этот номер телефона будет оформлена подписка</p>
        <div className="CalculatorBottomBlock">
          <div className="CalculatorBottomLeftBlock">
            <p className="CalculatorTextFirst">Месяцев</p>
            <p className="CalculatorText">Цена</p>
            {!!activeSubscription.discount1 && <p className="CalculatorText">Скидка</p>}
            {!!activeSubscription.discount2 && <p className="CalculatorText">Доп.скидка</p>}
            {code && <p className="CalculatorText">Скидка по коду</p>}
            {(!!activeSubscription.discount1 || code) && <p className="CalculatorTextSum">Итого к оплате</p>}
          </div>
          <div className="CalculatorBottomRightBlock">
            <SubscriptionSelect
              availableSubscriptions={availableSubscriptions}
              activeSubscription={activeSubscription}
              onSelect={onSelectSubscription}
            />
            <div className="CalculatorBottomRightBlockText">
              <p className="CalculatorText">
                {activeSubscription.price} <span className="RubleIcon">₽</span>
              </p>
              {!!activeSubscription.discount1 && <p className="CalculatorText">-{activeSubscription.discount1}%</p>}
              {!!activeSubscription.discount2 && <p className="CalculatorText">-{activeSubscription.discount2}%</p>}
              {code && <p className="CalculatorText">-{promocodeDiscount}%</p>}
              {(!!activeSubscription.discount1 || code) &&
                <p className="CalculatorTextSum">
                  {data?.price} <span className="RubleIcon">₽</span>
                </p>}
            </div>
          </div>
        </div>

      </div>
      <div className="CalculatorFooter">
        <div className="CalculatorDiscountContainer">
          <div className="CalculatorDiscountBlock">
            {!code && <CustomInput placeholder="У меня есть код на скидку" onChange={(value) => handleCodeChange(value)} inputClassName="CalculatorDiscountInput" minLength={4} maxLength={20} />}
            {code && !error && <p className="CalculatorCodeText">Ваш код успешно применен</p>}
            {!code && <CustomButton title="Применить" onClick={onClickCode} className="CalculatorDiscountButton" disabled={codeReq} />}
          </div>
          {code && <div className="CalculatorErrorBlock">
            <CustomButton title="Отменить" onClick={onClickResetCode} className="CalculatorDiscountButton" />
          </div>}
        </div>
        {error && <div className="CalculatorErrorBlock">
          <p className="CalculatorCodeTextError">Указанный код на скидку не найден или не действителен</p>
        </div>}

        <CustomButton title="Перейти к оплате" onClick={onClick} disabled={!phoneFlag} />
        <p className="CalculatorOffer">
          {"Оформляя покупку, вы соглашаетесь с "}
          <a className="CalculatorLink" href="https://privacy.podarok.prostoapp.ru">Договором оферты</a>
        </p>
      </div>
    </div>
  );
}

export default PromoCalculator;

