import DownloadCertificateApi from "./api";

class DownloadCertificateController {
  private api = new DownloadCertificateApi();

  downloadCertificate(id: string, text: string): Promise<boolean> {
    return this.api.downloadCert({
      id,
      text
    });
  }
}

export default DownloadCertificateController;
