import React from 'react';
import Calculator from '../Calculator/Calculator';
import defaultStyles from './themes/default/default.module.css';
import pinkStyles from './themes/pink/pink.module.css';
import { IMainScreenState } from "../../types/IMainScreenState";
import PromoCalculator from '../PromoCalculator/PromoCalculator';

interface IProps {
  promoCalculator?: boolean;
  data: IMainScreenState;
  onBuyClick: () => void;
  onDataChange: (newState: IMainScreenState) => void;
}

const defaultTheme = {
  title: 'Prosto — твоя внутренняя опора',
  subtitle: 'Многие из вас столкнулись с тем, что не могут продлить подписку на приложение в Apple Store и Google Play.',
  subtitleExtra: 'Чтобы вне зависимости от обстоятельств вы имели доступ к Prosto app, вы можете сегодня оплатить сертификат для себя или близкого человека на удобный для вас период, а активировать его позже.',
}

const pinkTheme = {
  title: 'Признайся в любви себе и близким!',
  subtitle: 'Подари подписку на год медитаций в Prosto с большой скидкой. Подарочный сертификат открывает доступ ко всем медитациям и образовательным видео приложения.',
  subtitleExtra: '',
}

const UpperMainBlock: React.FC<IProps> = ({ data, onBuyClick, onDataChange, promoCalculator }) => {
  // TODO: get theme, title, subtitle, prices from the server

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');

  const febHolidays = mm === '02' && ['11', '12', '13', '14'].includes(dd)

  const theme = febHolidays ? pinkTheme : defaultTheme;
  const styles = febHolidays ? pinkStyles : defaultStyles;

  return (
    <div className={styles.UpperMainRoot}>
      <div className={styles.ProstoAppIcon} />
      <div className={styles.MainBlock}>
        <div className={styles.FakeTextContainer} />
        <div className={styles.MainContainer}>
          {promoCalculator
            ? <PromoCalculator data={data} onClick={onBuyClick} onDataChange={onDataChange} />
            : <Calculator data={data} onClick={onBuyClick} onDataChange={onDataChange} />}
        </div>
        <a className={styles.HowItWorks} href="#advices">Как это работает?</a>
        <div className={styles.TextContainer}>
          <p className={styles.MainTitle}>{theme.title}</p>
          <p className={styles.AdditionalText}>{theme.subtitle}</p>
          <p className={styles.AdditionalText}>{theme.subtitleExtra}</p>
        </div>
      </div>
      <div className={styles.BackgroundImageContainer}>
        <div className={styles.BackgroundImage} />
      </div>
    </div>
  );
}

export default UpperMainBlock;
