import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BottomMainBlock from '../../components/BottomMainBlock/BottomMainBlock';
import Footer from '../../components/Footer/Footer';
import DownloadCertificateController from '../../core/certificateDownload/controller';
import config from '../../core/config/config';
import './CertificateScreen.css';

interface IScreenParams {
  promocodeId: number;
  text: string;
}
const controller = new DownloadCertificateController();
const prostoAppIcon = "images/ProstoappIcon.png";
const messageIcon = "images/Message.png";
const certificateIcon = "images/CertificateDownload.png";
const firstBlockText = `Скачайте сертификат из письма или с этой страницы`;
const secondBlockText = "Распечатайте сертификат и подарите, или перешлите его тому, кому делаете подарок";

const CertificateScreen: React.FC = () => {
  const location = useLocation<IScreenParams>();
  const [showModal, setShowModal] = useState(false)

  const handleActivateClick = useCallback(() => {
    controller.downloadCertificate(location.state.promocodeId.toString(), location.state.text).then((res: any) => {
      setShowModal(false);
      if (!res.error) {
        var link = document.createElement('a');
        link.href = `${config.apiUrl}/promocodes/${location.state.promocodeId.toString()}/certificate?text=${location.state.text}`;
        link.download = 'certificate.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setShowModal(true);
      }
    })
  }, [location.state.promocodeId]);

  return (
    <div className="CertificateBlock">
      <img className="AppIcon" src={prostoAppIcon} />
      <img className="MessageIcon" src={messageIcon} />
      <p className="TitleText">Сертификат с промокодом <br /> будет отправлен на указанную почту <br /> после завершения обработки платежа</p>
      <BottomMainBlock buttonTitle={"Скачать"} certificate onActivateClick={handleActivateClick} removeTitle downloadIcon firstImage={certificateIcon} firstText={firstBlockText} secondText={secondBlockText} />
      {showModal && <p className="DownloadError">Платеж находится в обработке или не оплачен</p>}
      <Footer />
    </div>
  );
}

export default CertificateScreen;
