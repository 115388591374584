import React, { useCallback, useState } from 'react';
import FooterButton from '../FooterButton/FooterButton';
import './Footer.css';

const Footer: React.FC = () => {

  const getOS = useCallback(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'itms-apps://itunes.apple.com/us/app/id149089182?mt=8';
    } else if (/Android/.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.prostoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1')
    }
  }, []);

  return ( 
    <div className="FooterBlock">
      <p className="FooterText">© Prosto 2020</p>
      <div className="FooterButtonContainer">
        <a href='https://play.google.com/store/apps/details?id=com.prostoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="googleContainer"/>
        </a>
        <a href="https://apps.apple.com/ru/app/просто-медитация-и-сон/id1490891826?mt=8" className="iosContainer"></a>
      </div>
      <p className="FooterText">Служба поддержки <a href="mailto: pr@prostoapp.com" className="FooterMail">pr@prostoapp.com</a></p>
    </div>
  );
}


export default Footer;
