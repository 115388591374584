import { TPayMethod } from "../../types/TPayMethod";
import config from "../config/config";
import httpService from "../http/HttpService";

const PROMO_CODES_URL = `${config.apiUrl}/promocodes`;

interface ICreatePromoCodeRequest {
  phone?: string;
  email: string;
  months: number;
  amountTotal: number;
  price: number;
  isCorporate: boolean;
  method: TPayMethod;
  text?: string;
  discount?: number;
  discountCorporation?: number;
  companyName?: string,
  inn?: string,
  workPhone?: string,
  legalAddress?: string
}

interface ICreatePromoCodeResponse {
  id: number;
  text: string;
  amountTotal: number;
  amountLeft: number;
  months: number;
  price: number;
  phone: string;
  email: string;
  method: string;
  isCorporate: boolean;
  creationDate: string;
  paymentDate: string;
}

class BuyGiftSubscriptionApi {
  async createPromoCode(data: ICreatePromoCodeRequest): Promise<ICreatePromoCodeResponse | null> {
    const response = await httpService.post(`${PROMO_CODES_URL}/buy`, data);
    return response.success ? response.data as ICreatePromoCodeResponse : null;
  }
}

export default BuyGiftSubscriptionApi;
