import React from 'react';
import './CertificateBlock.css';

const letter = "images/Letter.png";
const certificate = "images/Certificate.png";
interface IProps {
  firstImage?: string;
  firstText?: string;
  secondText?: string;
}

const CertificateBlock: React.FC<IProps> = ({ firstImage, firstText, secondText}) => {

  return ( 
    <div className="CertificateMainBlock">
      <div className="CertificateContainer">
        <img className="CertificateImage" src={firstImage} />
        <p className="CertificateTextFirst">{firstText}</p>
      </div>
      <div className="CertificateContainer">
        <img className="CertificateImage" src={letter} />
        <p className="CertificateText">{secondText}</p>
      </div>
      <div className="CertificateContainer">
        <img className="CertificateImage" src={certificate} />
        <p className="CertificateText">Получатель сертификата сможет легко активировать промокод, инструкция есть в сертификате</p>
      </div>
    </div>
  );
}

export default CertificateBlock;
